@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .icon-inline {
        @apply relative bottom-0.5 mx-1
    }
}

#webpack-dev-server-client-overlay {
    // display: none;
}