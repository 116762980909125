.media-player-wrapper {
    max-width: 100%;
    max-height: 75vh;
    height: 75vh;
    background-color: black;
    position: relative;

    .media-player {
        position: absolute;
        top: 0;
        bottom: 4rem;
        width: 100%;
        height: auto;
        overflow: hidden;

        video {
            /* Make video to at least and at most 100% wide and tall */
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;

            /* Setting width & height to auto prevents the browser from stretching or squishing the video */
            width: auto;
            height: auto;

            /* Center the video */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .media-controls {
        position: absolute;
        bottom: 0;
        height: 4rem;
        display: flex;
        width: 100%;
        padding: 1rem;
    }
}
