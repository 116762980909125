@import "../../assets/scss/themes-vars.module";

.document-page {
    /* Content Width */

    & > *,
    img {
        max-width: 100%;
    }

    /* Inline Code */

    code {
        color: $primaryMain;
        background-color: $primaryLight;
        border-radius: 4px;
    }

    code,
    kbd,
    pre,
    samp {
        font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    }

    code,
    kbd {
        padding: 2px 4px;
        font-size: 90%;
    }

    /* Tables */

    table {
        border-spacing: 0;
        border-collapse: collapse;
        display: block;
        width: max-content;
        max-width: 100%;

        tr:nth-child(odd) {
            background-color: $grey100;
            border-top: 1px solid $grey300;
            
            &:nth-child(even){
                background-color: $paper;
            }
        }

        th, td {
            padding: 6px 13px;
            border: 1px solid $grey300;
        }
    }
}
