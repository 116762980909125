@import '../../scss/themes-vars.module';

.alert {
    padding: 0px 0px 5px 0px;
    color: inherit;
    background-color: inherit;
    border: none;
    box-shadow: 0px 2px 2px 0px rgba(100, 100, 100, 0.4);
    border-radius: 4px;

    & > p {
        margin-bottom: 0;
        padding: 0 1rem;
    }

    & > ul {
        margin-bottom: 0;
        padding: 5px 40px;
    }

    & > h5 {
        font-size: 1.1rem;
        padding: 0.25rem 0.75rem;
        margin-top: 0;
        margin-bottom: .25rem;
        /* text-transform: uppercase; */
        font-weight: bold;
        border-radius: 4px 4px 0 0;
    }

    &.alert-info > h5 {
        color: #1976d2;
        border-bottom: 4px solid #1976d2;
        background-color: #e3f2fd;
    }

    &.alert-warning {
        background-color: $orangeLight;
        // & > * {
        //     color: $primaryLight;
        // }
    }

    &.alert-warning > h5 {
        color: $orangeMain;
        border-bottom: 4px solid $orangeDark;
        background-color: #fff3e0;
    }

    &.alert-danger {
        background-color: $errorLight;
    }

    &.alert-danger > h5 {
        color: $errorMain;
        border-bottom: 4px solid $errorDark;
        background-color: #ffebee;
    }
}
